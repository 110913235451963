<template>
  <div id="jobSelector">
    <v-container fill-height style="align-content: center" fluid>
      <v-row align="center" justify="center">
        <v-col cols="12" style="padding: 6px !important; padding-bottom: 12px !important">
          <v-card
            class="darkCard"
            :class="{
              'pa-4': !smallScreen,
              'pa-2': smallScreen,
              'mt-12': !smallScreen,
              'mt-6': smallScreen,
            }"
          >
            <v-img
              :width="fontSize * 5 + 'em'"
              :height="fontSize * 5 + 'em'"
              class="mainPageImageOffset"
              :src="require('../../assets/images/logo.svg')"
            ></v-img>
            <h1
              style="color: white; text-align: right"
              :style="{ 'font-size': fontSize + 'em' }"
              class="mb-4 pr-2"
            >
              Wonach<br />
              suchst du?
            </h1>
            <div style="display: flex; width: 100%">
              <div
                :disabled="disabled"
                class="customCheckbox mb-4"
                @click="switchAusbildungEnabled"
              >
                <input
                  :disabled="disabled"
                  type="checkbox"
                  id="ausbildungCheckbox"
                  aria-label="Ausbildung suchen auswählen"
                />
                <p
                  :style="{
                    'font-size': fontSize * 3 + 'em',
                    opacity: ausbildungChecked ? 1 : 0,
                  }"
                >
                  X
                </p>
                <v-icon :style="{ 'font-size': fontSize * 1.5 + 'em' }" color="accent"
                  >mdi-checkbox-blank-outline</v-icon
                >
                <h1
                  style="text-align: right"
                  :style="{ 'font-size': fontSize + 'em' }"
                  class="ml-4 pr-2"
                  @focus="focusAusbildung()"
                  id="ausbildungHeader"
                  :disabled="disabled"
                >
                  Ausbildung
                </h1>
              </div>
            </div>
            <div style="display: flex; width: 100%">
              <div
                class="customCheckbox"
                @click="switchPraktikumEnabled"
                :disabled="disabled"
              >
                <input
                  :disabled="disabled"
                  type="checkbox"
                  id="praktikumCheckbox"
                  aria-label="Praktikum suchen auswählen"
                />
                <p
                  :style="{
                    'font-size': fontSize * 3 + 'em',
                    opacity: praktikumChecked ? 1 : 0,
                  }"
                >
                  X
                </p>
                <v-icon :style="{ 'font-size': fontSize * 1.5 + 'em' }" color="accent"
                  >mdi-checkbox-blank-outline</v-icon
                >
                <h1
                  :disabled="disabled"
                  class="ml-4 pr-2"
                  :style="{ 'font-size': fontSize + 'em' }"
                  id="praktikumHeader"
                >
                  Praktikum
                </h1>
              </div>
            </div>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  name: "jobTypeSelector",

  props: ["disabled"],

  data() {
    return {
      ausbildungChecked: false,
      praktikumChecked: false,

      typeFilter: this.$SearchFilterHandler.searchFilters.typeFilter, //needed for watcher to work
    };
  },

  watch: {
    ausbildungChecked() {
      this.$SearchFilterHandler.setTypeSelection({
        ausbildung: this.ausbildungChecked,
        praktikum: this.praktikumChecked,
      });
    },
    praktikumChecked() {
      this.$SearchFilterHandler.setTypeSelection({
        ausbildung: this.ausbildungChecked,
        praktikum: this.praktikumChecked,
      });
    },
    "$SearchFilterHandler.searchFilters.typeFilter": {
      handler() {
        if (
          this.ausbildungChecked !=
          this.$SearchFilterHandler.searchFilters.typeFilter.ausbildung
        )
          this.ausbildungChecked = this.$SearchFilterHandler.searchFilters.typeFilter.ausbildung;

        if (
          this.praktikumChecked !=
          this.$SearchFilterHandler.searchFilters.typeFilter.praktikum
        )
          this.praktikumChecked = this.$SearchFilterHandler.searchFilters.typeFilter.praktikum;
      },
      deep: true,
    },
  },

  computed: {
    fontSize() {
      if (window.innerHeight < 750) {
        return 1.5;
      }

      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 1.5;
        case "sm":
          return 2;
        case "md":
          return 2.5;
        case "lg":
          return 2.5;
        case "xl":
          return 2.5;
        default:
          return 3;
      }
    },
    smallScreen() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return true;
        case "sm":
          return true;
        default:
          return false;
      }
    },
  },

  methods: {
    search() {
      this.$emit("search");
    },
    switchAusbildungEnabled() {
      this.ausbildungChecked = !this.ausbildungChecked;
      document.getElementById("ausbildungCheckbox").checked = this.ausbildungChecked;

      document.getElementById("ausbildungHeader").style.fontSize = this.fontSize + "em";
    },
    switchPraktikumEnabled() {
      this.praktikumChecked = !this.praktikumChecked;
      document.getElementById("praktikumCheckbox").checked = this.praktikumChecked;

      document.getElementById("praktikumHeader").style.fontSize = this.fontSize + "em";
    },
  },

  mounted() {
    document.getElementById("ausbildungCheckbox").addEventListener("keypress", (e) => {
      if (e.key == "Enter") {
        this.switchAusbildungEnabled();
      }
    });

    document.getElementById("praktikumCheckbox").addEventListener("keypress", (e) => {
      if (e.key == "Enter") {
        this.switchPraktikumEnabled();
      }
    });

    document.getElementById("ausbildungCheckbox").addEventListener("focus", () => {
      document.getElementById("ausbildungHeader").style.fontSize =
        this.fontSize * 1.15 + "em";
    });

    document.getElementById("ausbildungCheckbox").addEventListener(
      "blur",
      () => {
        document.getElementById("ausbildungHeader").style.fontSize = this.fontSize + "em";
      },
      true
    );

    document.getElementById("praktikumCheckbox").addEventListener("focus", () => {
      document.getElementById("praktikumHeader").style.fontSize =
        this.fontSize * 1.15 + "em";
    });

    document.getElementById("praktikumCheckbox").addEventListener(
      "blur",
      () => {
        document.getElementById("praktikumHeader").style.fontSize = this.fontSize + "em";
      },
      true
    );

    if (
      this.ausbildungChecked !=
      this.$SearchFilterHandler.searchFilters.typeFilter.ausbildung
    )
      this.ausbildungChecked = this.$SearchFilterHandler.searchFilters.typeFilter.ausbildung;

    if (
      this.praktikumChecked !=
      this.$SearchFilterHandler.searchFilters.typeFilter.praktikum
    )
      this.praktikumChecked = this.$SearchFilterHandler.searchFilters.typeFilter.praktikum;

    const urlParams = new URLSearchParams(window.location.href);
    if (urlParams.get("praktikum")) {
      this.praktikumChecked = true;

      this.$SearchFilterHandler.setTypeSelection({
        ausbildung: false,
        praktikum: true,
      });
    }

  },
};
</script>
