<template>
  <div>
    <v-carousel
      v-model="slide"
      cycle
      interval="10000"
      continuous
      :show-arrows="false"
      id="mainPageSlider"
    >
      <v-carousel-item v-for="(image, i) in images" :key="i" style="height=100%">
        <v-sheet height="100%" tile>
          <v-row
            class="fill-height"
            align="center"
            justify="center"
            style="margin: 0px !important"
          >
            <v-img :src="image.file"></v-img>
          </v-row>
        </v-sheet>
      </v-carousel-item>
    </v-carousel>

    <div id="mainPage">
      <headerComponent :disabled="brafoDrawer || mobileFilterDrawer ? true : false" />

      <div id="mainPageCenterDiv">
        <!-- <v-container style="position: absolute">
          <v-row>
        <v-spacer />
        <v-col>
          <MaintenanceVue />
        </v-col>
        <v-spacer />
      </v-row>

        </v-container> -->

        <v-container v-if="nonMobileBrafo">
          <v-row>
            <v-spacer />
            <v-col :cols="cardWidth">
              <searchByField @search="gotoSearch" @close="nonMobileBrafo = false" />
            </v-col>
            <v-spacer />
          </v-row>

          <v-row v-if="!isMobile" style="z-index: 0; position: relative">
            <v-spacer />
            <v-col :cols="eventCols">
              <eventBanner :events="events" />
            </v-col>
            <v-spacer />
          </v-row>
        </v-container>

        <v-container v-else>
          <v-row>
            <v-spacer />
            <v-col :cols="cardWidth">
              <jobTypeSelector
                :disabled="brafoDrawer || mobileFilterDrawer ? true : false"
              />
            </v-col>

            <v-spacer />
          </v-row>
          <v-row class="ma-0 pa-0">
            <v-spacer />
            <v-col :cols="cardWidth" class="ma-0 pa-0">
              <searchComponent
                :disabled="brafoDrawer || mobileFilterDrawer ? true : false"
                @search="gotoSearch"
                @showMobileFilter="mobileFilterDrawer++"
              />
            </v-col>
            <v-spacer />
          </v-row>
          <v-row class="ma-0 pa-0">
            <v-spacer />
            <v-col
              class="ma-0 px-0 pt-0"
              style="text-align: center; font-weight: bold; color: var(--v-accent-base)"
              >ODER</v-col
            >
            <v-spacer />
          </v-row>
          <v-row class="ma-0 pa-0">
            <v-spacer />

            <v-col :cols="cardWidth" class="ma-0 pa-0 px-3">
              <v-badge
                color="primary"
                :content="countActiveFieldFilters()"
                overlap
                style="width: inherit"
                :class="{ hiddenBadge: countActiveFieldFilters() == 0 }"
              >
                <v-btn
                  v-if="isMobile"
                  :disabled="brafoDrawer || mobileFilterDrawer ? true : false"
                  block
                  large
                  @click.stop="showBrafoDrawer()"
                  :class="{ darkOutline: isMobile }"
                  >Tätigkeitsfelder</v-btn
                >
                <v-btn
                  v-else
                  :disabled="brafoDrawer || mobileFilterDrawer ? true : false"
                  block
                  large
                  @click="nonMobileBrafo = true"
                  >Tätigkeitsfelder</v-btn
                >
              </v-badge>
            </v-col>

            <v-spacer />
          </v-row>

          <v-row v-if="!isMobile" style="z-index: 0; position: relative">
            <v-spacer />
            <v-col :cols="eventCols">
              <eventBanner :events="events" />
            </v-col>
            <v-spacer />
          </v-row>
        </v-container>

        <div
          style="position: absolute; top: 50vh; cursor: pointer;"
          @click="gotoInformation()"
          v-if="!isMobile"
          :style="{ right: rightImageOffset }"
        >
          <v-img
            src="../assets/images/mehrinfos.png"
            style="height: 200px; width: 200px"
            class="imageContain"
          >
          </v-img>
        </div>
      </div>
    </div>

    <mobileSearchByField
      @search="gotoSearch"
      :open="brafoDrawer"
      @closeDrawer="brafoDrawer = 0"
    />

    <mobileSearchFilter
      @search="gotoSearch"
      :open="mobileFilterDrawer"
      @closeDrawer="mobileFilterDrawer = 0"
    />

    <eventPage
      class="my-16"
      v-if="!brafoDrawer && !mobileFilterDrawer"
      :events="events"
    />

    <newsAndInfo></newsAndInfo>

    <footerComponent v-if="!brafoDrawer && !mobileFilterDrawer" />
  </div>
</template>

<script>
import headerComponent from "../components/Main/Header.vue";
import footerComponent from "../components/Main/Footer.vue";
import searchComponent from "./Search/Search.vue";
import mobileSearchByField from "../components/Search/SearchByField_MobileDrawer.vue";
import searchByField from "../components/Search/SearchByField.vue";
import jobTypeSelector from "../components/Search/JobTypeSelector.vue";
import eventPage from "./Calendar/EventPage.vue";
import eventBanner from "./Calendar/EventBanner.vue";
import mobileSearchFilter from "../components/Search/SearchFilter_MobileDrawer.vue";
import newsAndInfo from "../components/Main/NewsAndInfo.vue";
// import MaintenanceVue from '@/components/Main/Maintenance.vue';

import { permissions } from "../util/permissions.js";
// const json = require("./output.json");

// const json = require("./cities.json");

const axios = require("axios");
/**
 * Its my component
 * @component
 * @vue-prop {String} msg - Initial counter's value
 */
export default {
  name: "mainPage",

  data() {
    return {
      brafoDrawer: 0, //show brafo for mobile
      nonMobileBrafo: false, //show brafo for non mobile

      mobileFilterDrawer: 0,

      slide: 0,

      images: [],
      brafo: [],
      events: [],

      fieldInputs: this.$SearchFilterHandler.searchFilters.brafoSelections,
    };
  },

  metaInfo: {
    title: "Hallo-Beruf, Digitale Ausbildungs- und Praktikumsbörse der IHK Halle-Dessau",
    titleTemplate: "%s - Startseite",
    htmlAttrs: {
      lang: "de",
    },
    meta: [
      { charset: "utf-8" },
      { name: "viewport", content: "width=device-width, initial-scale=1" },
    ],
  },

  components: {
    headerComponent,
    footerComponent,
    searchComponent,
    mobileSearchByField,
    searchByField,
    eventPage,
    eventBanner,
    jobTypeSelector,
    mobileSearchFilter,
    newsAndInfo,
    // MaintenanceVue
  },

  watch: {
    "$SearchFilterHandler.searchFilters.brafoSelections": {
      handler() {
        this.countActiveFieldFilters();
      },
      deep: true,
    },
  },

  computed: {
    rightImageOffset() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return "100px";
        case "sm":
          return "100px";
        case "md":
          return "0vw";
        case "lg":
          return "10vw";
        case "xl":
          return "20vw";
        default:
          return "100px";
      }
    },
    cardWidth() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return "11";
        case "sm":
          return "10";
        case "md":
          return "10";
        case "lg":
          return "6";
        case "xl":
          return "5";
        default:
          return "6";
      }
    },
    isMobile() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return true;
        case "sm":
          return true;
        case "md":
          return false;
        case "lg":
          return false;
        case "xl":
          return false;
        default:
          return false;
      }
    },
    fontSize() {
      if (window.innerHeight < 750) {
        return 1;
      }
      if (window.innerHeight < 950) {
        return 1.2;
      }

      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 1;
        case "sm":
          return 1.2;
        case "md":
          return 1.5;
        case "lg":
          return 2;
        case "xl":
          return 2;
        default:
          return 2;
      }
    },
    eventCols() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 12;
        case "sm":
          return 12;
        case "md":
          return 10;
        case "lg":
          return 10;
        case "xl":
          return 8;
        default:
          return 8;
      }
    },
    smallHeight() {
      if (window.innerHeight < 750) {
        return true;
      }
      return false;
    },
  },

  methods: {
    gotoInformation() {
      this.$router.push("/information/schueler");
    },
    showBrafoDrawer() {
      window.scrollTo(0, 0);
      ++this.brafoDrawer;
    },
    countActiveFieldFilters() {
      let count = 0;
      for (
        let i = 0;
        i < this.$SearchFilterHandler.searchFilters.brafoSelections.length;
        i++
      ) {
        if (this.$SearchFilterHandler.searchFilters.brafoSelections[i]) {
          ++count;
        }
      }

      return "" + count;
    },
    gotoSearch() {
      this.$router.push("/suche/" + this.$SearchFilterHandler.getSearchQEncoded());
    },
    scrollToEvents() {
      document.querySelector("#eventPage").scrollIntoView({
        behavior: "smooth",
      });
    },
    fetchData() {
      this.loading = true;

      axios.get(axios.defaults.baseURL + "backgroundimages/public/").then((resp) => {
        this.images = resp.data;

        let rand = Math.floor(Math.random() * this.images.length);
        this.slide = rand;

        this.fetchBrafos();
      });
    },
    fetchBrafos() {
      axios.get(axios.defaults.baseURL + "brafo").then((resp) => {
        this.brafo = resp.data;

        this.fetchEvents();
      });
    },

    fetchEvents() {
      axios
        .get("events")
        .then((resp) => {
          this.events = resp.data;

          this.$forceUpdate();
        })
        .catch((e) => {
          console.log(e);
          window.dispatchEvent(new CustomEvent("showErrorDisplay"));
        });
    },
  },

  mounted() {
    this.fetchData();

    this.countActiveFieldFilters();

    let accessToken = this.$store.state.accessToken;

    if (accessToken) {
      accessToken = atob(accessToken.split(".")[1]);

      const now = Math.floor(Date.now() / 1000);
      if (accessToken.exp < now) {
        this.$store.dispatch("logoutUser");
        window.location.reload();
      } else {
        if (this.$store.state.permission == permissions.UNTERNEHMEN) {
          this.$router.push("/unternehmen/unternehmensprofil");
        } else if (this.$store.state.permission <= permissions.REDAKTEUR) {
          this.$router.push("/redakteure/dashboard");
        }
      }
    }
  },
};
</script>
