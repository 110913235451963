var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',{staticClass:"py-12",class:{
      'pa-8': !_vm.setupView,
      scrollable: _vm.cardHeight != undefined,
      hiddenScroll: _vm.cardHeight != undefined,
    },staticStyle:{"min-height":"50vh"},style:({
      width: _vm.cardWidth,
      height: _vm.cardHeight != undefined ? _vm.cardHeight : 'auto',
      'max-height':
        _vm.isMobile ? '80vh' : (_vm.cardHeight == 'auto' || !_vm.cardHeight ? '80vh' : '80vh'),

      overflow: 'auto',
    }),attrs:{"flat":""}},[(!_vm.setupView)?_c('v-btn',{staticClass:"mt-2 headerButton",staticStyle:{"position":"absolute","right":"0%","top":"0%"},attrs:{"text":"","id":"closeButton"},on:{"click":function($event){return _vm.close()}}},[_c('v-icon',[_vm._v("mdi-close")])],1):_vm._e(),_c('v-form',{ref:"companyForm"},[_c('v-container',{attrs:{"fluid":""}},[_c('v-row',{staticClass:"mb-6"},[_c('v-col',{staticClass:"py-0 px-3"},[(_vm.isMobile)?_c('h2',{staticClass:"encodeFont mb-2 px-3"},[_vm._v("Filialen- information")]):_c('h2',{staticClass:"encodeFont px-3"},[_vm._v("Filialeninformation")])])],1),_c('v-row',[_c('v-col',{staticClass:"pb-0",attrs:{"cols":_vm.mainCols}},[_c('v-container',{staticClass:"pb-0 pr-0",class:{ 'pt-0': _vm.isMobile },attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{staticClass:"pt-0"},[_c('v-text-field',{attrs:{"label":"Firmenname*","outlined":"","rules":[_vm.required]},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}})],1)],1),_c('v-row',[_c('v-col',{staticClass:"py-0"},[_c('v-combobox',{staticClass:"maxWidthCombobox",attrs:{"hide-selected":"","return-object":"","items":_vm.jobNames,"item-text":"name","multiple":"","outlined":"","small-chips":"","label":"Ausbildungsberufe","rules":[_vm.arrayOfObjects]},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
    var attrs = ref.attrs;
    var item = ref.item;
    var parent = ref.parent;
    var selected = ref.selected;
return [_c('v-chip',_vm._b({attrs:{"color":((item.color) + " lighten-3"),"input-value":selected,"label":"","small":""}},'v-chip',attrs,false),[_c('span',{staticClass:"pr-2"},[_vm._v(" "+_vm._s(item.name)+" ")]),_c('v-icon',{attrs:{"small":""},on:{"click":function($event){return parent.selectItem(item)}}},[_vm._v(" mdi-close ")])],1)]}},{key:"item",fn:function(ref){
    var item = ref.item;
return [_c('v-container',{staticStyle:{"max-width":"40vw !important","margin-left":"0"}},[_c('v-row',[_c('v-col',[_vm._v(" "+_vm._s(item.name)+" ")])],1)],1)]}}]),model:{value:(_vm.allowedApprenticeships),callback:function ($$v) {_vm.allowedApprenticeships=$$v},expression:"allowedApprenticeships"}})],1)],1),_c('AddressComponentVue',{staticClass:"pa-0 mt-3",attrs:{"isRequired":true,"addressData":_vm.address,"disabled":false,"validateNow":_vm.validateNow,"removePadding":true},on:{"formValid":_vm.validAddressForm}}),_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('v-text-field',{attrs:{"label":"Webseite","outlined":""},model:{value:(_vm.weblink),callback:function ($$v) {_vm.weblink=$$v},expression:"weblink"}})],1),(!_vm.editorView)?_c('v-col',[_c('v-combobox',{attrs:{"items":_vm.contacts,"label":"Kontaktpersonen","outlined":"","multiple":""},scopedSlots:_vm._u([{key:"item",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(item.first_name)+" "+_vm._s(item.last_name)+" ")]}},{key:"selection",fn:function(ref){
    var attrs = ref.attrs;
    var item = ref.item;
    var parent = ref.parent;
    var selected = ref.selected;
return [_c('v-chip',_vm._b({attrs:{"color":((item.color) + " lighten-3"),"input-value":selected,"label":"","small":""}},'v-chip',attrs,false),[_c('span',{staticClass:"pr-2"},[_vm._v(" "+_vm._s(item.first_name)+" "+_vm._s(item.last_name)+" ")]),_c('v-icon',{attrs:{"small":""},on:{"click":function($event){return parent.selectItem(item)}}},[_vm._v(" mdi-close ")])],1)]}}],null,false,1621345432),model:{value:(_vm.selectedContacts),callback:function ($$v) {_vm.selectedContacts=$$v},expression:"selectedContacts"}})],1):_vm._e(),(!_vm.editorView)?_c('v-col',{staticClass:"pl-0",attrs:{"cols":"auto"}},[_c('v-btn',{staticClass:"primaryOnWhiteButton thinLine pa-0 lightOutline",staticStyle:{"height":"56px !important","width":"56px"},attrs:{"large":"","block":"","elevation":"0"},on:{"click":function($event){return _vm.showEditContact()}}},[_c('v-icon',[_vm._v(" mdi-plus")])],1)],1):_vm._e()],1)],1)],1),_c('v-col',{staticClass:"pb-0",class:{ 'pr-0': _vm.isMobile },attrs:{"cols":_vm.imageCols}},[_c('v-container',{staticClass:"pb-0 pr-3",class:{ 'pt-0': _vm.isMobile },staticStyle:{"display":"flex","flex-direction":"column"},style:({ height: _vm.isMobile ? 'unset' : 'calc(100% - 32px)' }),attrs:{"fluid":""}},[_c('v-row',{staticClass:"mb-2 pa-0"},[_c('v-spacer'),_c('v-col',{staticClass:"pa-0 pr-3",attrs:{"cols":"12"}},[(_vm.logo)?_c('img',{staticClass:"imageContain",staticStyle:{"object-fit":"contain !important","height":"219px","width":"100%"},attrs:{"src":_vm.getImgSrc(_vm.logo),"alt":'Firmenlogo für ' +
                      (_vm.companyData && _vm.companyData.name
                        ? _vm.companyData.name
                        : 'neues Event')}}):_c('img',{staticClass:"imageContain",staticStyle:{"object-fit":"contain !important","height":"219px","width":"100%"},attrs:{"src":require("../../../assets/images/noImg.svg"),"alt":'Platzhalterlogo für ' +
                      (_vm.companyData && _vm.companyData.name
                        ? _vm.companyData.name
                        : 'neues Event')}})])],1),_c('v-row',{staticClass:"pr-3",class:{ 'px-3': _vm.isMobile, 'mb-4': _vm.setupView }},[_c('v-col',{staticClass:"pa-0",class:{
                    'pr-1': !_vm.isMobile,
                    'px-3': _vm.setupView,
                    'pr-0': _vm.isMobile,
                    'mb-4': _vm.isMobile,
                  },attrs:{"cols":_vm.isMobile ? 12 : 6}},[_c('div',{staticClass:"imageUploader",on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.showUpload.apply(null, arguments)}}},[_c('image-uploader',{staticClass:"imageUploadFocus",staticStyle:{"height":"56px"},attrs:{"tabindex":"0","debug":0,"maxWidth":1024,"maxHeight":1024,"quality":0.6,"autoRotate":false,"outputFormat":"verbose","preview":false,"accept":"image/*","id":"logoID"},on:{"input":_vm.setImage},model:{value:(_vm.logo),callback:function ($$v) {_vm.logo=$$v},expression:"logo"}},[_c('label',{staticStyle:{"width":"inherit !important"},attrs:{"slot":"upload-label","id":"uploadLabel","for":"logoID"},slot:"upload-label"},[_c('span',{staticClass:"primaryOnWhiteButton thinLine lightOutline pa-2 focusable",staticStyle:{"border-radius":"4px !important","display":"flex","align-items":"center","text-align":"center","justify-content":"center"},attrs:{"block":"","elevation":"0"}},[_c('v-icon',{staticClass:"mr-3 primary--text"},[_vm._v("mdi-camera")]),_vm._v(" Hochladen")],1)])])],1)]),_c('v-col',{staticClass:"pa-0",class:{ 'pl-1': !_vm.isMobile, 'mb-7': _vm.isMobile, 'pl-0': _vm.isMobile },attrs:{"cols":_vm.isMobile ? 12 : 6}},[_c('v-btn',{staticClass:"primaryOnWhiteButton thinLine lightOutline px-2 py-3 ma-0",staticStyle:{"height":"56px !important"},attrs:{"block":"","elevation":"0"},on:{"click":function($event){_vm.logo = null}}},[_c('v-icon',{staticClass:"mr-3"},[_vm._v("mdi-delete")]),_vm._v("Löschen")],1)],1)],1)],1)],1)],1),(_vm.editorView)?_c('v-row',[_c('v-col',{attrs:{"cols":12}},[_c('EditContactVue',{attrs:{"contactData":_vm.companyData && _vm.companyData.contacts
                  ? _vm.companyData.contacts[0]
                  : undefined,"companyId":_vm.companyData ? _vm.companyData.id : undefined,"setupView":false,"component":true},on:{"change":_vm.changeContact}})],1)],1):_vm._e(),(!_vm.setupView)?_c('v-row',[_c('v-spacer'),_c('v-col',{staticClass:"px-6",attrs:{"cols":_vm.isMobile ? 12 : 4}},[_c('v-btn',{staticClass:"primaryOnWhiteButton lightOutline py-3",attrs:{"block":"","elevation":"0"},on:{"click":function($event){return _vm.close()}}},[_c('v-icon',{staticClass:"mr-4 pa-1"},[_vm._v("mdi-cancel")]),_vm._v(" Abbrechen ")],1)],1),_c('v-spacer'),_c('v-col',{staticClass:"pr-6",class:{ 'pl-0': !_vm.isMobile, 'pl-6': _vm.isMobile },attrs:{"cols":_vm.isMobile ? 12 : 8}},[_c('v-btn',{staticClass:"primaryButton py-3",attrs:{"block":"","elevation":"0"},on:{"click":function($event){return _vm.saveData()}}},[_c('v-icon',{staticClass:"mr-4"},[_vm._v("mdi-check")]),_vm._v(" Speichern ")],1)],1),_c('v-spacer')],1):_vm._e()],1)],1),_c('div',{staticStyle:{"height":"0px","opacity":"0"},attrs:{"tabindex":"0"},on:{"focus":_vm.resetFocus}},[_vm._v(" Fokus zurück zu \"Schließen\" ")])],1),_c('v-overlay',{attrs:{"value":_vm.loading}},[_c('v-progress-circular',{attrs:{"indeterminate":"","color":"accent"}})],1),_c('v-overlay',{attrs:{"value":_vm.showContactEditView,"dark":false}},[_c('EditContactVue',{attrs:{"contactData":_vm.activeContact},on:{"close":_vm.closeEditView}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }